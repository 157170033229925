var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{class:[
  {
    'full-window': _vm.activeDialog !== 'AddEditFAQ' &&
      _vm.activeDialog !== 'BusinessAccountData' &&
      _vm.activeDialog !== 'BusinessAccountClients' &&
      _vm.activeDialog !== 'UserData' &&
      _vm.activeDialog !== 'FundsInfo' &&
      _vm.activeDialog !== 'GetUsersXLS' &&
      _vm.activeDialog !== 'GetPromoCodes' &&
      _vm.activeDialog !== 'MarkData' &&
      _vm.activeDialog !== 'GetBusinessXLS' &&
      _vm.activeDialog !== 'TransactionsXLS' &&
      _vm.activeDialog !== 'PromoData' &&
      _vm.activeDialog !== 'ReducedСommissions' &&
      _vm.activeDialog !== 'AddDocument' &&
      _vm.activeDialog !== 'SetCommission' &&
      _vm.activeDialog !== 'ReferralData'
  },
  {
    'full-window--small': _vm.activeDialog === 'AddEditUser' ||
      _vm.activeDialog === 'EditingLimit' ||
      _vm.activeDialog === 'AdditionalManager' ||
      _vm.activeDialog === 'GetUsersXLS' ||
      _vm.activeDialog === 'MarkData' ||
      _vm.activeDialog === 'TransactionsXLS' || _vm.activeDialog === 'AddEditRepresentative'
  },
  { 'no-padding': _vm.activeDialog === 'ReducedСommissions' },
],attrs:{"visible":_vm.dialogVisible,"before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c(_vm.activeDialog,{tag:"component"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }